import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero";
import HowItWorks from "../components/howitworks";
import SkillSet from "../components/skillset";
import WhyUs from "../components/why";
import CTA from "../components/cta";

const Index = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: imageSharp(fluid: { originalName: { eq: "home-bg.jpg" } }) {
        fluid(quality: 90, maxWidth: 1920) {
          presentationWidth
          presentationHeight
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      mobile: imageSharp(fluid: { originalName: { eq: "home-offshore.jpg" } }) {
        fluid(maxWidth: 270, maxHeight: 180) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      web: imageSharp(fluid: { originalName: { eq: "home-scalability.jpg" } }) {
        fluid(maxWidth: 270, maxHeight: 180) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      backend: imageSharp(fluid: { originalName: { eq: "home-norm.jpg" } }) {
        fluid(maxWidth: 270, maxHeight: 180) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      others: imageSharp(
        fluid: { originalName: { eq: "home-expertise.jpg" } }
      ) {
        fluid(maxWidth: 270, maxHeight: 180) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  `);

  const heroTitle = (
    <>
      Hire <em className="font-weight-light">Good</em> Talents
    </>
  );

  const skills = {
    title: "Finding the right skillset",
    sets: [
      {
        title: "Any offshore talents",
        image: data.mobile.fluid,
        list: [
          "Programmers",
          "Digital marketers",
          "Accounting executive",
          "Call centre personal"
        ]
      },
      {
        title: "Scalability",
        image: data.web.fluid,
        list: [
          "40% lower cost",
          "Expandable team",
          "Tap to 100 million population size",
          "Flat fee"
        ]
      },
      {
        title: "New Norm",
        image: data.backend.fluid,
        list: [
          "Remote working",
          "Offshore feasibility",
          "Dedicated talents",
          "Full Commitment"
        ]
      },
      {
        title: "Our Expertise",
        image: data.others.fluid,
        list: [
          "HR management",
          "Payroll processing",
          "Tax Filing",
          "Resourcefulness"
        ]
      }
    ]
  };

  const whyUs = {
    title: (
      <>
        <span className="font-weight-normal">Why</span> Choose Us
      </>
    ),
    sets: [
      {
        title: "Dedicated Talents",
        desc:
          "Every talent on Tech2Reach is a full-time worker. " +
          "This means they have proven abilities, their time is well-managed, " +
          "and they are dedicated to your company – and your company only."
      },
      {
        title: "Unique talent pool",
        desc:
          "By allowing you to interview and select talent directly, " +
          "Tech2Reach ensures you talented talents are not freelancers."
      },
      {
        title: "Thorough vetting process",
        desc:
          "Every talent on Tech2Reach is handpicked for quality and talent. " +
          "Forget choosing between freelancers – Tech2Reach shows you " +
          "high-quality, verified talent, every time."
      },
      {
        title: "Scale at will",
        desc:
          "Tech2Reach’s model is fine-tuned for quickly growing businesses " +
          "who need longer-term dedicated tech solutions. Leverage the HR and " +
          "management expertise of managing talents and save yourself " +
          "time. Add or subtract talents to your team quickly."
      }
    ]
  };

  return (
    <Layout>
      <SEO
        title="Home"
        pathname="/"
        image={data.hero.fluid}
        keywords={[
          "singapore",
          "hire",
          "tech",
          "foreign",
          "talent",
          "offshore",
          "outsource",
          "programmer",
          "software",
          "developer",
          "accounting",
          "customer",
          "service",
          "executives",
          "marketing",
          "digital",
          "marketers"
        ]}
      />
      <Hero
        background={data.hero.fluid}
        title={heroTitle}
        subTitle="Getting talents couldn't be any easier than this"
        buttonText="Get In Touch"
      />
      <HowItWorks
        title1="No hiring limits"
        desc1="Engage as many overseas talents as you want. Building an offshore talent team at a much lower cost."
        title2="Cost savings"
        desc2="Up to 40% cost saving engaging offshore junior or senior talents as compared to hiring a local"
        title3="Dedicated talents"
        desc3="A talent fully dedicated to you. Fully committed. Just like a local staff, working from home. Not a freelancer. Doesn't handle other company's projects."
        title4="Don't sweat it"
        desc4="We manage and comply with the overseas regulations. Payroll, taxation, legal compliance, office space, welfare and all other human resource aspects are all taken care of by us."
      />
      <SkillSet skills={skills} />
      <WhyUs why={whyUs} />
      <CTA />
    </Layout>
  );
};

export default Index;
